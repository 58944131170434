const firebaseConfig = {
  apiKey: "AIzaSyC2ZG2k6SpFvHML8ktMNfksyVpdJv175jA",
  authDomain: "virualonline.firebaseapp.com",
  projectId: "virualonline",
  storageBucket: "virualonline.appspot.com",
  messagingSenderId: "714033348634",
  appId: "1:714033348634:web:de78f502cd8d9cac4f11a3",
  measurementId: "G-41B0Y68MCM"
};
export default firebaseConfig 
