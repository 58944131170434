import 'antd/lib/style/index.less' // antd core styles
import './@kit/vendors/antd/themes/default.less' // default theme antd components
import './@kit/vendors/antd/themes/dark.less' // dark theme antd components
import './global.scss' // app & third-party component styles

import React from 'react'
import ReactDOM from 'react-dom'
import moment from "moment";
import { createHashHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router'
import { fb, db, rdb, auth, storage } from 'services/firebase'
import packageJson from "../package.json";
// clear cache
// import moment from "moment";
// import packageJson from "../package.json";
import withClearCache from "./ClearCache";

import reducers from './redux/reducers' // store DATA, INITAIL and use from compose connect
import sagas from './redux/sagas' // store FUNCTION and use from compose connect dispatch

// node genfile/switch.js sale-agent

import Localization from './localization'
import Router from './app/virtualDiprom/router'

import * as serviceWorker from './serviceWorker'
import Firebase, { FirebaseContext } from './components/Firebase';
// mocking api
import 'services/axios/fakeApi'

// middlewared
const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]
if (process.env.NODE_ENV === 'development') {
  // middlewares.push(logger)
}
const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

// const ClearCacheComponent = withClearCache(<Router history={history} />);

const renderReactDom = () => {
  ReactDOM.render(
    <ProviderDom />,
    document.getElementById('root'),
  )
};

const ProviderDom = () => {
  // const [shouldRefresh, setShouldRefresh] = React.useState(false);
  // React.useEffect(() => {
  //   const countdown = 1000 // 1 seconds
  //     * 5
  //     // * 60 // 1 minutes
  //     // * 60 // 1 house
  //     // * 6  // 6 hours in milliseconds
  //   const id = setTimeout(
  //     () => {
  //       setShouldRefresh(true)
  //       console.log('forceRefresh reloaded')
  //     }, 
  //     countdown
  //     );
  //   console.log('clearTimeout = ', id)
  //   console.log('countdown = ', countdown/1000)
  //   return () => {
  //     clearTimeout(id)
  //   }
  // }, [])

  const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate)
    const momCurrentDateTime = moment(currentDate)
    if(momLatestDateTime.isAfter(momCurrentDateTime))
      return true
    else
      return false
  }
  const [lastCheck, setLastCheck] = React.useState(new Date().getTime())
  const getLatestAppVersion = () => {
    const countdown = 1000 // 1 seconds
      * 5 // 60 = 1 minutes
      // * 60 // 60 = 1 hours
      // * 6  // 6 hours in milliseconds
    const id = setTimeout(
      () => {
        checkLatestApp()
      }, 
      countdown
      );
    // console.log(`clearTimeout = ${id} /countdown = `, countdown/1000)
    return () => {
      clearTimeout(id)
    }
  }
  const checkLatestApp = () => {
    // const currentCheck = new Date().getTime()
    // const diffCheck = currentCheck - lastCheck
    // console.log(`_project_ last ${lastCheck} / current ${currentCheck} / diff ${diffCheck} `)
    // if(diffCheck > 10 * 1000){
    db.collection('_project_').doc('app').get()
    .then((snapshort) => {
      const data = snapshort.data()
      // console.log('_project_ > app = ',data) 
      if(data){
        const latestVersionDate = data.buildDate
        const currentVersionDate = packageJson.buildDate
        const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate)
        if(shouldForceRefresh) 
          refreshCacheAndReload()
        else 
          console.log(`${moment().format("HH:mm:ss")} latest version =`, data)
      }
    })
    .catch(function func(err) {
      console.log("err = ", err)
    })
    //   setLastCheck(currentCheck)
    //   console.log('getLatestAppVersion reloaded')
    // }
    // // else
    // //   console.log('diffCheck = ', diffCheck)
  }
  const refreshCacheAndReload = () => {
    console.log('caches = ', caches)
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      console.log('caches.keys() = ', caches.keys())
      caches.keys().then((names) => {
        names.forEach(name =>
          caches.delete(name)
        )
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  }

  const countdown = 1000 // 1 seconds
      * 60 // 60 seconds = 1 minutes
      * 5 // 60 minute = 1 hours
      // * 6  // 6 hours in milliseconds
  React.useEffect(() => {
    const interval = setInterval(() => {
      checkLatestApp()
      // console.log('Logs every minute')
    }, countdown);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  return (
    <Provider store={store}>
      <FirebaseContext.Provider value={new Firebase()}>
        <Localization>
          {/* <ClearCacheComponent /> */}
          {/* shouldRefresh = {JSON.stringify(shouldRefresh)} */}
          <Router history={history} />
          {/* <Router history={history} getLatestAppVersion={getLatestAppVersion} /> */}
          {/* <Router history={history} forceRefresh={shouldRefresh} /> */}
        </Localization>
      </FirebaseContext.Provider>
    </Provider>
  )
}

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}

// const ClearCacheComponent = withClearCache(MainApp);

// function App() {
//   return <ClearCacheComponent />;
// }

// function MainApp(props) {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>Build date: {getBuildDate(packageJson.buildDate)}</p>
//       </header>
//     </div>
//   );
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
export { store, history }
