import React, { lazy, Suspense } from 'react'

export const menuFront = [
    { url: '/web/entrance', key: 'entrance', title: 'Virtual Exhibition', },
    { url: '/web/shopShowList', key: 'shop', title: 'Shop', },
    { url: '/web/productShowList', key: 'product', title: 'Product', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', icon: 'fe fe-phone-call', disabled: true, },
    // { url: '/web/promotion', key: 'promotion', title: 'โปรโมชั่น', },
    // { url: '/web/productGroupList', key: 'productGroupList', title: 'สินค้า', },
    // { url: '/web/agent', key: 'agent', title: 'ร่วมเป็นตัวแทนกับเรา', },
    // { url: '/web/contact', key: 'contact', title: 'ติดต่อเรา', },
    // { url: '/web/cart', key: 'cart', title: 'Cart', icon: 'fe fe-shopping-cart', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // { url: '/web/contact', key: 'contact', title: 'CONTACT', },
    // {
    //     title: 'Dashboards',
    //     key: 'dashboards',
    //     icon: 'fe fe-home',
    //     roles: ['admin'], 
    //     count: 8,
    //     children: [
    //     {
    //         title: 'Test',
    //         key: 'dashboardTest',
    //         url: '/dashboard/test',
    //     },
    //     ]
    // },
]

export const menuBackConfig = {
    menuBackground: {backgroundColor:"#222323"},
    menuColor1: {color:"#FFF"},
    menuColor2: {color:"#CCC"},
}
export const menuBack = {
    my: {
        title: '',
        roles: [ 'member'],
        status: 'active',
        menu: [
            { sector: "sectorSelf", title: "ส่วนตัว" },
            { icon: "fe fe-file-text", to: "../userMyProfile", title: "ข้อมูลส่วนตัว" },
            // { sector: "sectorEntrance", title: "Entrance" },
            
            // { icon: "fe fe-chevron-right", to: "../shopList", title: "Shop" },
            // { icon: "fe fe-chevron-right", to: "../shopLinkList", title: "Shop Link" },
            // { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            // { icon: "fe fe-chevron-right", to: "../productLinkList", title: "Product Link" },

            { sector: "sectorVirtual", title: "Virtual Exhibition" },
            { icon: "fe fe-chevron-right", to: "../dashboard/entrance", title: "Entrance" },
            // { icon: "fe fe-chevron-right", to: "../zoneList", title: "Zone" },
            { icon: "fe fe-chevron-right", to: "../shopShowList", title: "Shop" },
            { icon: "fe fe-chevron-right", to: "../productShowList", title: "Product" },

        ],
    },
    dev: {
        title: 'Dev',
        roles: [ 'dev'],
        status: 'active',
        menu: [
            { icon: "fe fe-chevron-right", to: "../userList", title: "User Management" },
            { icon: "fe fe-chevron-right", to: "../goldSavingList", title: "Gold Saving" },
            { icon: "fe fe-chevron-right", to: "../testimonialList", title: "Testimonial" },
            { icon: "fe fe-chevron-right", to: "../homeSlideList", title: "Home Slide" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../videoList", title: "Video" },
            { icon: "fe fe-chevron-right", to: "../videoGroupList", title: "Video Group" },
            { icon: "fe fe-chevron-right", to: "../promotionList", title: "Promotion" },
            { icon: "fe fe-chevron-right", to: "../promotionGroupList", title: "Promotion Group" },
            { icon: "fe fe-chevron-right", to: "../registerLeadList", title: "Customer Register" },
            { icon: "fe fe-chevron-right", to: "../registerServiceList", title: "Register Service" },
            { icon: "fe fe-chevron-right", to: "../budgetList", title: "Budget" },
            { icon: "fe fe-chevron-right", to: "../serviceTypeList", title: "Service Type" },
            { icon: "fe fe-chevron-right", to: "../projectImageList", title: "Project Image" },
        ],
    },
    admin: {
        title: '',
        roles: [ 'admin'],
        status: 'active',
        menu: [
            // { sector: "sector1", title: "ส่วนตัว" },
            // { icon: "fe fe-file-text", to: "../userMyProfile", title: "ข้อมูลส่วนตัว" },
            // { sector: "sectorOnline", title: "Entrance" },
            // { icon: "fe fe-pie-chart", to: "../dashboard/entrance", title: "Virtual Exhibition" },
            // { sector: "sectorUser", title: "Management" },
            // { icon: "fe fe-users", to: "../userList", title: "User Management" },
            // { sector: "sectorDashboard", title: "Dashboard" },
            // { icon: "fe fe-pie-chart", to: "../dashboard/overall", title: "Overall" },
            { sector: "sectorVirtualAdmin", title: "Virtual Exhibition (admin)" },
            // { icon: "fe fe-chevron-right", to: "../zoneList", title: "Zone" },
            { icon: "fe fe-chevron-right", to: "../shopList", title: "Shop" },
            { icon: "fe fe-chevron-right", to: "../shopLinkList", title: "Shop Link" },
            { icon: "fe fe-chevron-right", to: "../productList", title: "Product" },
            { icon: "fe fe-chevron-right", to: "../productLinkList", title: "Product Link" },

            { sector: "sectorRegister", title: "Registration" },
            { icon: "fe fe-chevron-right", to: "../customerInterestRegisterList", title: "Customer Interest" },

        ],
    },
}

export const route = [

    // auth
    { path: '/auth/login', Component: lazy(() => import('app/virtualDiprom/pages/auth/login'))},
    { path: '/auth/register', Component: lazy(() => import('app/virtualDiprom/pages/auth/register'))},

    // dashboard
    { path: '/dashboard/overall', Component: lazy(() => import('app/virtualDiprom/pages/dashboard/overall'))},
    { path: '/dashboard/dashboard', Component: lazy(() => import('app/virtualDiprom/pages/dashboard/dashboard'))},
    { path: '/dashboard/entrance', Component: lazy(() => import('app/virtualDiprom/pages/dashboard/entrance'))},

    { path: '/web/entrance', Component: lazy(() => import('app/virtualDiprom/pages/dashboard/entrance'))},
    
    // user
    { path: '/userList', Component: lazy(() => import('app/virtualDiprom/pages/user/list'))},
    { path: '/user/:id', Component: lazy(() => import('app/virtualDiprom/pages/user/edit'))},
  
    { path: '/userMyProfile', Component: lazy(() => import('app/_custom/pages/userMyProfile')), exact: true,},

    // home
    { path: '/web/thankYou', Component: lazy(() => import('app/virtualDiprom/pages/web/thankYou'))},
    { path: '/web/privacy', Component: lazy(() => import('app/virtualDiprom/pages/web/privacy'))},
  
    { path: '/web/home', Component: lazy(() => import('app/virtualDiprom/pages/web/home'))},
    { path: '/web/homeRegister', Component: lazy(() => import('app/virtualDiprom/pages/web/homeRegister'))},
    { path: '/web/about', Component: lazy(() => import('app/virtualDiprom/pages/web/about'))},
  
    { path: '/customerInterestRegisterList', Component: lazy(() => import('app/virtualDiprom/pages/customerInterestRegister/list'))},
    { path: '/customerInterestRegister/:id', Component: lazy(() => import('app/virtualDiprom/pages/customerInterestRegister/edit'))},

    { path: '/zoneList', Component: lazy(() => import('app/virtualDiprom/pages/zone/list'))},
    { path: '/zone/:id', Component: lazy(() => import('app/virtualDiprom/pages/zone/edit'))},
  
    { path: '/shopList', Component: lazy(() => import('app/virtualDiprom/pages/shop/list'))},
    { path: '/shop/:id', Component: lazy(() => import('app/virtualDiprom/pages/shop/edit'))},
    { path: '/shopLinkList', Component: lazy(() => import('app/virtualDiprom/pages/shop/link'))},
    { path: '/shopShow/:id', Component: lazy(() => import('app/virtualDiprom/pages/shop/show'))},
    { path: '/shopShowList', Component: lazy(() => import('app/virtualDiprom/pages/shop/showList'))},
  
    { path: '/productList', Component: lazy(() => import('app/virtualDiprom/pages/product/list'))},
    { path: '/product/:id', Component: lazy(() => import('app/virtualDiprom/pages/product/edit'))},
    { path: '/productLinkList', Component: lazy(() => import('app/virtualDiprom/pages/product/link'))},
    { path: '/productShow/:id', Component: lazy(() => import('app/virtualDiprom/pages/product/show'))},
    { path: '/productShowList', Component: lazy(() => import('app/virtualDiprom/pages/product/showList'))},



    { path: '/web/shopShow/:id', Component: lazy(() => import('app/virtualDiprom/pages/shop/show'))},
    { path: '/web/shopShowList', Component: lazy(() => import('app/virtualDiprom/pages/shop/showList'))},
    { path: '/web/productShow/:id', Component: lazy(() => import('app/virtualDiprom/pages/product/show'))},
    { path: '/web/productShowList', Component: lazy(() => import('app/virtualDiprom/pages/product/showList'))},
  
  
]