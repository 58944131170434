import React, { useState, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Menu, Dropdown } from 'antd'
import { find } from 'lodash'
import classNames from 'classnames'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import LanguageSwitcher from './LanguageSwitcher'
import style from './style.module.scss'
import styles from './LanguageSwitcher/style.module.scss'
import { menuFront }  from '../../../menu'
import projectConfig  from '../../../config'
import UserMenu from './UserMenu'

const mapStateToProps = ({ menu, settings, user }) => ({
  // menuData: menu.menuData,
  menuData: [
    ...menuFront,
  ],
  locale: settings.locale,
  settings,
  flyoutActive: !settings.isMobileView,
  user,
})

const flyoutTimers = {}
let flyoutItems = {}

const MenuTop = ({ dispatch, locale , menuData = [], location: { pathname }, settings, flyoutActive, user }) => {
  const [activeSubmenu, setActiveSubmenu] = useState('')
  const [activeItem, setActiveItem] = useState('')
  const [renderedFlyoutItems, setRenderedFlyoutItems] = useState({})

  useEffect(() => {
    setActiveItems()
  }, [pathname, menuData])

  const changeLanguage = ({key}) => {
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'locale',
        value: key,
      },
    })
  }
  const language = locale.substr(0, 2)

  const toggleSettings = () => {
    const { isSidebarOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSidebarOpen',
        value: !isSidebarOpen,
      },
    })
  }

  const toggleMobileMenu = e => {
    e.preventDefault()
    const { isMobileMenuOpen } = settings
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMobileMenuOpen',
        value: !isMobileMenuOpen,
      },
    })
  }

  const handleSubmenuClick = (e, key) => {
    e.preventDefault()
    if (flyoutActive) {
      return
    }
    setActiveSubmenu(activeSubmenu === key ? '' : key)
  }

  const handleFlyoutOver = (event, key, items) => {
    if (flyoutActive) {
      clearInterval(flyoutTimers[key])
      const item = event.currentTarget
      const itemDimensions = item.getBoundingClientRect()
      const element = renderFlyoutMenu(items, key, itemDimensions)
      setRenderedFlyoutItems({
        ...renderedFlyoutItems,
        [key]: element,
      })
      flyoutItems = {
        ...renderedFlyoutItems,
        [key]: element,
      }
    }
  }

  const handleFlyoutOut = key => {
    if (flyoutActive) {
      flyoutTimers[key] = setTimeout(() => {
        delete flyoutItems[key]
        setRenderedFlyoutItems({
          ...flyoutItems,
        })
      }, 100)
    }
  }

  const handleFlyoutContainerOver = key => {
    clearInterval(flyoutTimers[key])
  }

  const renderFlyoutMenu = (items, key, itemDimensions) => {
    const left = `${itemDimensions.left + itemDimensions.width / 2}px`
    const top = `${itemDimensions.top + itemDimensions.height}px`

    return (
      <div
        style={{ left, top }}
        className={classNames(style.air__menuFlyout, {
          [style.air__menuFlyoutTop]: settings.menuLayoutType === 'top',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
        })}
        key={key}
      >
        <ul
          className={style.air__menuTop__list}
          onMouseEnter={() => handleFlyoutContainerOver(key)}
          onMouseLeave={() => handleFlyoutOut(key)}
        >
          {items.map(item => {
            return (
              <li
                className={classNames(style.air__menuTop__item, {
                  [style.air__menuTop__item__active]: activeItem === item.key,
                })}
                key={item.key}
              >
                <Link to={item.url} className={style.air__menuTop__link}>
                  {item.icon && <i className={`${item.icon} ${style.air__menuTop__icon}`} />}
                  <span>{item.title}</span>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  const setActiveItems = () => {
    if (!menuData.length) {
      return
    }
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item)
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key))
        }
        return flattenedItems
      }, [])
    const currentItem = find(flattenItems(menuData, 'children'), ['url', pathname]) || {}
    const currentSubmenu = menuData.reduce((key, parent) => {
      if (Array.isArray(parent.children)) {
        parent.children.map(child => {
          if (child.key === currentItem.key) {
            key = parent
          }
          return ''
        })
      }
      return key
    })
    setActiveItem(currentItem.key)
    setActiveSubmenu(currentSubmenu.key)
  }

  const generateMenuItems = () => {
    const menuItem = item => {
      const { key, title, icon, url } = item
      if (item.category) {
        return null
      }
      return (
        <li
          className={classNames(style.air__menuTop__item, {
            [style.air__menuTop__item__active]: activeItem === key,
          })}
          key={key}
        >
          {item.url && (
            <Link to={url} className={style.air__menuTop__link} 
              style={{
                // marginTop: settings.isMobileMenuOpen ? '' : "15px", 
                fontSize: '18px',
                // textTransform: 'uppercase',
                // margin : settings.isMobileMenuOpen ? '' : '41px 15px 39px 15px',
              }}>   
              {icon && <i className={`${icon} ${style.air__menuTop__icon}`} />}
              <span>{title}</span>
            </Link>
          )}
          {!item.url && (
            <a href="#" onClick={e => e.preventDefault()} className={style.air__menuTop__link}>
              {icon && <i className={`${icon} ${style.air__menuTop__icon}`} />}
              <span>{title}</span>
            </a>
          )}
        </li>
      )
    }

    const submenuItem = item => {
      return (
        <li
          className={classNames(
            style.air__menuTop__item, 
            style.air__menuTop__submenu, 
            {
            [style.air__menuTop__submenu__active]: activeSubmenu === item.key,
          })}
          key={item.key}
          style={{
            marginTop: settings.isMobileMenuOpen ? '' : "15px", 
            fontSize: '18px',
            backgroundColor: '#FFF',
          }}
        >
          <a
            href="#"
            className={style.air__menuTop__link}
            onClick={e => handleSubmenuClick(e, item.key)}
            onMouseEnter={event => handleFlyoutOver(event, item.key, item.children)}
            onFocus={event => handleFlyoutOver(event, item.key, item.children)}
            onMouseLeave={() => handleFlyoutOut(item.key)}
            onBlur={() => handleFlyoutOut(item.key)}
            style={{
              color: '#786fa4',
              fontSize: "18px", 
            }}
          >
            <i className={`${item.icon} ${style.air__menuTop__icon}`} />
            <span>{item.title}</span>
            {item.count && (
              <span className="badge text-white bg-blue-light float-right mt-1 px-2">
                {item.count}
              </span>
            )}
          </a>
          <ul className={style.air__menuTop__list}>
            {item.children.map(sub => {
              if (sub.children) {
                return submenuItem(sub)
              }
              return menuItem(sub)
            })}
          </ul>
        </li>
      )
    }

    return menuData.map(item => {
      if (item.children) {
        return submenuItem(item)
      }
      return menuItem(item)
    })
  }

  const items = generateMenuItems()

  const menu = (
    <Menu selectedKeys={[locale]} onClick={changeLanguage}>
      <Menu.Item key="en-US">
        <span className="text-uppercase font-size-18 mr-2">EN</span> English
      </Menu.Item>
      <Menu.Item key="th-TH">
        <span className="text-uppercase font-size-18 mr-2" style={{fontSize:"50"}}>TH</span> ไทย
      </Menu.Item>
    </Menu>
  )

  return (
    <div>
      <TransitionGroup>
        {Object.keys(renderedFlyoutItems).map(item => {
          return (
            <CSSTransition key={item} timeout={0} classNames="air__menuFlyout__animation">
              {renderedFlyoutItems[item]}
            </CSSTransition>
          )
        })}
      </TransitionGroup>
      <div
        style={{}}
        className={classNames(style.air__menuTop, {
          [style.air__menuTop__mobileToggled]: settings.isMobileMenuOpen,
          [style.air__menuTop__shadow]: settings.isMenuShadow,
          [style.air__menuTop__flyout]: true,
          // [style.air__menuTop__blue]: settings.menuColor === 'blue',
          [style.air__menuTop__white]: true,
          // [style.air__menuTop__gray]: settings.menuColor === 'gray',
          [style.air__menuFlyout__black]: settings.flyoutMenuColor === 'dark',
          [style.air__menuFlyout__white]: settings.flyoutMenuColor === 'white',
          [style.air__menuFlyout__gray]: settings.flyoutMenuColor === 'gray',
        })}
      >
        <div className={style.air__menuTop__outer}>
          <a href="#" className={style.air__menuTop__mobileToggleButton} onClick={toggleMobileMenu}>
            <span />
          </a>
          <Link to={`/web/home`} className={style.air__menuTop__logo}>
            {projectConfig.appLogo2Width || projectConfig.appLogo2}
          </Link>
          <div id="menu-left-container" 
            className={style.air__menuTop__container} 
            style={{fontSize:"x-large"}} >
            <ul className={style.air__menuTop__list}>
              {/* 
              <li className={style.air__menuTop__item}>
                <a href="#" className={style.air__menuTop__link} onClick={toggleSettings}>
                  <i className={`fe fe-settings ${style.air__menuTop__icon}`} />
                  <span>Settings</span>
                </a>
              </li>
              <li className={style.air__menuTop__item}>
                <a
                  href="https://docs.airuitemplate.com/"
                  className={style.air__menuTop__link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`fe fe-compass ${style.air__menuTop__icon}`} />
                  <span>Documentation</span>
                </a>
              </li> 
              */}
              { settings.isMobileMenuOpen && (
                <br />
              )}
              {items}
              {/* <li className={style.air__menuTop__item}>
                <Menu selectedKeys={[locale]} onClick={changeLanguage} style={{borderRight:"0", marginTop: settings.isMobileMenuOpen ? '': "7px"}}>
                  <Menu.Item key="en-US" style={{fontSize:"20px", marginTop: "9px", float: settings.isMobileMenuOpen ? '': 'left'}}>
                    <span className="text-uppercase font-size-20 mr-2">EN</span> 
                  </Menu.Item>
                  <Menu.Item key="th-TH" style={{fontSize:"20px", marginTop: "9px", float: settings.isMobileMenuOpen ? '': 'left'}}>
                    <span className="text-uppercase font-size-20 mr-2">TH</span> 
                  </Menu.Item>
                </Menu>
              </li> */}
            </ul>
            {/* Test Lang : 
            <FormattedMessage id="topBar.issuesHistory" /> */}
          </div>
          <div className="mr-auto d-none d-md-block">
            {/* <IssuesHistory /> */}
          </div>
            {/* <UserMenu /> */}
            {/* {JSON.stringify(user)} */}
          {
            user && user.id
            ?
              <>
                <div style={{marginTop: 15, }}>
                  <UserMenu />
                </div>
              </>
            :
             <>
              <Link to={'/auth/register'} 
                className='btn btn-ghost btn-circle' 
                style={{
                  marginTop: 15, 
                  fontSize: '18px',
                  marginRight: 0,
                  float: 'right',
                  height: 40,
                  borderRadius: '25px',
                }}>   
                <span><i className='fe fe-user' /> สมัครสมาชิก</span>
              </Link>
              <Link to={'/auth/login'} 
                className='btn btn-primary btn-circle' 
                style={{
                  // marginTop: settings.isMobileMenuOpen ? '' : 15, 
                  marginTop: 15, 
                  fontSize: '18px',
                  marginRight: 0,
                  float: 'right',
                  height: 40,
                  borderRadius: '25px',
                  // textTransform: 'uppercase',
                  // margin : settings.isMobileMenuOpen ? '' : '41px 15px 39px 15px',
                }}>   
                <span><i className='fe fe-lock' /> เข้าสู่ระบบ</span>
              </Link>
             </>
          }
        </div>
      </div>
      <a href="#" className={style.air__menuTop__backdrop} onClick={toggleMobileMenu} />
    </div>
  )
}
export default withRouter(connect(mapStateToProps)(MenuTop))