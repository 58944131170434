import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import { googleAnalytic } from 'config/dbUtils'
import { FacebookProvider, CustomChat } from 'react-facebook';
import projectConfig from './config'
import { route } from './menu'
import { route as mainRoute } from '../_custom/menu_general'

import Layout from './layouts'

const routes = [
  ...route,
  ...mainRoute,

  // Auth Pages
  { path: '/auth/login', Component: lazy(() => import('pages/auth/login')), exact: true, }, 
  { path: '/auth/forgot-password', Component: lazy(() => import('pages/auth/forgot-password')), exact: true, }, 
  { path: '/auth/register', Component: lazy(() => import('pages/auth/register')), exact: true, },
  { path: '/auth/lockscreen', Component: lazy(() => import('pages/auth/lockscreen')), exact: true, },
  { path: '/auth/404', Component: lazy(() => import('pages/auth/404')), exact: true, },
  { path: '/auth/500', Component: lazy(() => import('pages/auth/500')), exact: true, },
]

const mapStateToProps = ({ settings , user}) => ({
  routerAnimation: settings.routerAnimation, user
})

const Router = ({ history, routerAnimation, getLatestAppVersion, user}) => {

  React.useEffect(() => {
    // googleAnalytic({tracking_id : 'UA-120432092-1'})
    googleAnalytic(projectConfig && projectConfig.googleAnalytic)
  },[]);

  return (
    <ConnectedRouter history={history}>
      {
        projectConfig && projectConfig.facebook && projectConfig.facebook.pageId && projectConfig.facebook.appId && (
          // <FacebookProvider appId="474812183840866" chatSupport>
          //   <CustomChat pageId="391079311372123" minimized={false}/>
          // </FacebookProvider> 
          <FacebookProvider appId={projectConfig.facebook.appId} chatSupport>
            <CustomChat pageId={projectConfig.facebook.pageId} minimized={false}/>
          </FacebookProvider> 
        )
      }
      { window.location.href.indexOf("/page/")>-1 || window.location.href.indexOf("/page/")>-1 
      ?
      <div className="c-layout-page">
        {/* <Route exact path="/web/:id" component={WebShow}/> */}
        {routes.map(({ path, Component, exact }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => {
              return (
                <div className={routerAnimation}>
                  <Suspense fallback={null}>
                    <Component />
                  </Suspense>
                </div>
              )
            }}
          />
        ))}
      </div>
      :
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {
                      // user.role === 'member' ?
                      // <Route exact path="/" render={() => { window.location = "https://virtual.diprom-megamall.com"}} />
                      // : 
                      //   <>abc</>
                    }
                    {/* {
                      projectConfig && projectConfig.frontOffice === 'active'
                      ? <Route exact path="/" render={() => <Redirect to="/web/home" />} />
                      : <Route exact path="/" render={() => <Redirect to="/userMyProfile" />} />
                    } */}

                    <Route exact path="/loginSuccess" render={() => <Redirect to="/web/entrance" />} />
                    {/* <Route exact path="/loginSuccess" render={() => <Redirect to="/dashboard/entrance" />} /> */}
                    {/* {
                      user && user.role === 'admin'
                      ? <Route exact path="/loginSuccess" render={() => <Redirect to="/userMyProfile" />} />
                      : <Route exact path="/loginSuccess" render={() => { window.location = "https://virtual.diprom-megamall.com"}} />
                    } */}
                    {/* <Route exact path="/" render={() => <Redirect to="/userMyProfile" />} /> */}
                    <Route exact path="/" render={() => <Redirect to="/web/entrance" />} />
                    {/* <Route exact path="/loginSuccess" render={() => { window.location = "https://virtual.diprom-megamall.com"}} /> */}
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={(props) => {
                          // getLatestAppVersion()
                          return (
                            <div className={routerAnimation} >
                              <Suspense fallback={null} >
                              {/* {projectConfig.frontOffice} / 
                              user : {JSON.stringify(user.role)} */}

                                {/* props.match.params = {JSON.stringify(props.match.params)} */}
                                <Component props={props} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
      }
    </ConnectedRouter>
  )
}
export default connect(mapStateToProps)(Router)